import { ImageIcon } from 'lucide-react'
import React from 'react'
import { cn } from '#app/utils/misc.tsx'

type PlaceholderImageProps = {
  hidden?: boolean
}
const PlaceholderImage = React.forwardRef<
  HTMLDivElement,
  React.ImgHTMLAttributes<HTMLDivElement> & PlaceholderImageProps
>(({ hidden, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex h-full w-full items-center justify-center',
      'stroke-2 text-[#969696]',
      'bg-[#e7e7e7]',
      hidden ? 'hidden' : '',
      className,
    )}
    {...props}
  >
    <ImageIcon className="h-12 w-12" />
  </div>
))
PlaceholderImage.displayName = 'PlaceholderImage'

type LazyImageProps = {
  src?: string | null | undefined
  onLoad?: () => void
  onError?: () => void
}
const LazyImage = React.forwardRef<
  HTMLImageElement,
  Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> & LazyImageProps
>(({ src, onLoad, onError, className, ...props }, ref) => {
  const [loadedImageSrc, setLoadedImageSrc] = React.useState<string | null>(
    null,
  )

  const handleLoad = React.useCallback(() => {
    setLoadedImageSrc(src ?? null)
    onLoad && onLoad()
  }, [src, onLoad])
  const handleError = React.useCallback(() => {
    setLoadedImageSrc(null)
    onError && onError()
  }, [onError])

  React.useEffect(() => {
    if (src != null) {
      const img = new Image()
      img.src = src
      img.addEventListener('load', handleLoad)
      img.addEventListener('error', handleError)
      return () => {
        img.removeEventListener('load', handleLoad)
        img.removeEventListener('error', handleError)
      }
    }
  }, [src, handleLoad, handleError])

  return (
    <>
      <PlaceholderImage
        ref={ref}
        className={className}
        hidden={loadedImageSrc != null}
      />
      <img
        ref={ref}
        alt=""
        loading="lazy"
        className={cn(loadedImageSrc != null ? '' : 'hidden', className)}
        {...props}
        src={loadedImageSrc ?? ''}
      />
    </>
  )
})
LazyImage.displayName = 'LazyImage'

export { PlaceholderImage, LazyImage }
